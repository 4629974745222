.App {
    text-align: center;
    height: 100%;
    width: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #172554;
    min-height: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(4px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.text {
    text-align: left;
    padding: 16px 16px 16px 48px;
}

.flex {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 75%;
    float: right;
}

.flex-left {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 400px;
    padding-top: 24px;
    padding-bottom: 48px;
}


.flex-column {
    display: flex;
    flex-direction: column;
    width: 300px;
    align-content: center;

    :hover {
        cursor: pointer;
    }
}

.select-box {
    width: 300px;
    align-content: center;
}

.parent-container {
    position: relative;
    /* Additional styles for the container if needed */
}

.threat-box {
    position: absolute;
    top: 0;
    left: 24px;
    text-align: left;
}

.select-elements {
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 76%;
    float: right;
    text-align: left;
    justify-content: left;
    border-left: 1px #bfbfbf solid;
    padding-left: 24px;
}

.spacer {
    height: 16px;
}

body, html {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    height: 100%;
    padding: 0;
    margin: 0;
}

a:link {
    text-decoration: none;
}

.content,
.content > div {
    width: 100%;
    height: 100%;
    overflow: hidden;

}

.content > .message {
    text-align: center;
    display: table;
    font-size: 16px;
}

.content > .message .note {
    vertical-align: middle;
    text-align: center;
    display: table-cell;
    height: 80px;
}

.content .error .details {
    max-width: 500px;
    font-size: 12px;
    text-align: left;
}

.content .error pre {
    border: solid 1px #CCC;
    background: #EEE;
    padding: 10px;
}

.content:not(.with-error) .error,
.content.with-error .intro,
.content.with-diagram .intro {
    display: none;
}


.content .canvas {
    height: 60vh;
    width: 76%;
    float: right;
    border-bottom: #bfbfbf solid 1px;
    border-left: #bfbfbf solid 1px;
    padding: 12px;
    visibility: hidden;
}

.content.with-error .canvas {
    visibility: hidden;
}

.content.with-diagram .canvas {
    visibility: visible;
}

.buttons {
    position: absolute;
    top: 24px;
    right: 24px;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 12px;
}

.buttons > li {
    display: inline-block;
    margin-right: 10px;
}

.buttons > li > a {
    display: inline-block;
    padding: 5px;
    background-color: #3457c3;
    color: #ffffff;
    border: solid 1px black;
    font-weight: 700;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-radius: 4px;
    font-size: 14px;
    min-width: 160px;
    height: 20px;


}

button {
    border: solid 1px black;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-radius: 4px;
    background-color: #eeeeee;
    display: inline-block;
    height: 32px;
    min-width: 160px;
    list-style: none;
}

button:hover {
    cursor: pointer;
}

button.submit {
    background-color: #3457c3;
    color: #ffffff;
    border: solid 1px black;
    margin: 10px;
    font-weight: 700;
}

button.report {
    background-color: #3457c3;
    color: #ffffff;
    position: fixed;
    bottom: 24px;
    right: 24px;
    padding: 0;
    margin: 0;
    font-weight: 700;
    width: 240px;
}

button.back {
    position: fixed;
    top: 90px;
    left: 30px;
    padding: 0;
    margin: 0;
}

.multi-select {
    display: flex;
    flex-direction: column;
    width: 300px;
    align-content: center;
}


button.multi-select-box {
    border: #e2e2e2 solid;
    border-width: 1px 0px 0px 0px;
    text-align: left;
    background-color: white;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
}

button.multi-select-box:hover {
    background-color: #d9e3ff;
}

.check-box {
    border: #e2e2e2 solid 1px;
    border-radius: 2px;
    height: 10px;
    width: 10px;
    margin: 10px;
}

.check-box-fill {
    background-color: #172554;
    height: 8px;
    width: 8px;
    border-radius: 2px;
    margin: 1px;
}

.drop-down-box {
    display: flex;
    flex-direction: column;
    width: 300px;
    align-content: center;
}

.drop-down-item {
    border: 2px solid;
    border-radius: 4px;
    text-align: left;
    background-color: white;
    display: flex;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    justify-content: space-between;
    margin: 4px;
    padding: 16px;
    font-weight: 700;
}

.drop-down-item:hover {
    cursor: pointer;
}

.dropped-down {
    padding: 8px;
    border-top: #e2e2e2 1px solid;
    font-weight: 400;
}

.report {
    max-width: 800px;
}